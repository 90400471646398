import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

function guardRoute(to, from, next) {
    const auth = store.state.auth;

    if (!auth.isLoggedIn) {
        next({ path: "/", query: { redirect: to.fullPath } });
    } else {
        next();
    }
}

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
        isPublic: true
    },
    {
        path: "/quiz",
        name: "Quiz",
        component: () => import("../views/Quiz.vue"),
        isPublic: false
    },
    {
        path: "/configuration",
        name: "Configuration",
        component: () => import("../views/config/config_list.vue"),
        isPublic: true
    },
    {
        path: "/password-reset",
        name: "Passwort ändern",
        component: () => import("../views/config/password-reset.vue"),
        isPublic: false
    }
];

const router = new VueRouter({
    routes: routes.map(route => ({
        name: route.name,
        path: route.path,
        component: route.component,
        beforeEnter: (to, from, next) => {
            if (!route.isPublic) return guardRoute(to, from, next);
            next();
        }
    }))
});

export default router;
