import auth from "../auth/auth.store";

if (localStorage.getItem("TUIT")) {
    const syncedState = JSON.parse(localStorage.getItem("TUIT"));
    auth.state = Object.assign(auth.state, syncedState.auth);
}

const localStoragePlugin = store => {
    store.subscribe((mutation, state) => {
        const syncedData = { auth: state.auth };

        localStorage.setItem("TUIT", JSON.stringify(syncedData));
    });
};
export { localStoragePlugin };
