import Vue from "vue";

const getDefaultState = () => ({
    isLoggedIn: false,
    accessToken: null,
    friendlyUsername: null,
    userId: null,
    authorities: []
});

const auth = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            state = Object.assign({}, data);
        },
        updateFriendlyUsername(state, data) {
            Vue.set(state, "friendlyUsername", data);
        },
        updateAuthorities(state, data) {
            Vue.set(state, "authorities", data);
        },
        clear(state) {
            Object.assign(state, getDefaultState());
        }
    },

    actions: {
        clear({ commit }) {
            commit("clear");
        },
        update({ commit }, data) {
            commit("update", data);
        },
        updateFriendlyUsername(context, data) {
            context.commit("updateFriendlyUsername", data);
        },
        updateAuthorities(context, data) {
            context.commit("updateAuthorities", data);
        }
    },

    getters: {
        getAuth(state) {
            return state;
        },
        hasRoleUser(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes("ROLE_USER")) return true;
            }
            return false;
        },
        hasRoleAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes("ROLE_ADMIN")) return true;
            }
            return false;
        }
    }
};

export default auth;
