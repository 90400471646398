<template>
    <div id="app">
        <Sidebar />
        <Navbar />
        <router-view />
    </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Sidebar from "@/views/Sidebar";

export default {
    name: "Home",
    computed: {
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        }
    },
    components: {
        Sidebar,
        Navbar
    },
    data() {
        return {};
    },
    mounted() {}
};
</script>

<style>
@import "assets/styles/main.css";
#app {
    padding-top: 5.5em;
}
</style>
