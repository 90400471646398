import Vue from "vue";
import { BToast, ToastPlugin } from "bootstrap-vue";
import VueI18n from "@/i18n";

let bootStrapToaster = new BToast();

const Toast = {
    options: {
        toaster: "b-toaster-bottom-right",
        noCloseButton: true,
        autoHideDelay: 3000,
        solid: true
    },

    init() {
        bootStrapToaster = new BToast();
        Vue.use(ToastPlugin);
    },

    showSaveOkToastI18n(i18nConst, displayToast = true) {
        if (!i18nConst) i18nConst = "global.messages.toast.save_ok";
        if (displayToast) {
            this.options.variant = "success";
            bootStrapToaster.$bvToast.toast(
                VueI18n.tc(i18nConst),
                this.options
            );
        }
    },
    showDeleteOkToastI18n(i18nConst, displayToast = true) {
        if (!i18nConst) i18nConst = "global.messages.toast.delete_ok";
        if (displayToast) {
            this.options.variant = "success";
            bootStrapToaster.$bvToast.toast(
                VueI18n.tc(i18nConst),
                this.options
            );
        }
    },

    showSaveErrorToastI18n(i18nConst, errorObject) {
        if (!i18nConst) i18nConst = "global.messages.toast.save_nok";

        let message = VueI18n.tc(i18nConst);
        if (errorObject) {
            const errorMessage = this.extractErrorMessage(errorObject);
            if (message && message.length > 0) {
                message += ": " + errorMessage;
            }
        }
        this.options.variant = "danger";
        bootStrapToaster.$bvToast.toast(message, this.options);
    },

    showDeleteErrorToastI18n(i18nConst, errorObject) {
        if (!i18nConst) i18nConst = "global.messages.toast.delete_nok";

        let message = VueI18n.tc(i18nConst);
        if (errorObject) {
            const errorMessage = this.extractErrorMessage(errorObject);
            if (message && message.length > 0) {
                message += ": " + errorMessage;
            }
        }
        this.options.variant = "danger";
        this.options.autoHideDelay = 8000;
        bootStrapToaster.$bvToast.toast(message, this.options);
    },
    extractErrorMessage(errorObject) {
        let message = "";
        if (
            errorObject &&
            errorObject.response &&
            errorObject.response.data &&
            errorObject.response.data.message
        ) {
            message = errorObject.response.data.message;
        } else if (errorObject && errorObject.message) {
            message = errorObject.message;
        }
        return message;
    }
};

export default Toast;
{
    // eslint-disable-next-line no-unused-expressions
    () => ({
        resolve: null
    });
}
