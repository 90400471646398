import ApiService from "@/api";
import Toast from "@/common/toast";

const resource = "workshops";
const media_resource = "workshops/medialist";

const WorkshopService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n(null, e);
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n(null, e);
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            // delete with post because of server restrictions
            promise = await ApiService.post(resource + "/" + slug);
            // Toast.showDeleteOkToastI18n();
        } catch (e) {
            // Toast.showDeleteErrorToastI18n();
        }
        return promise;
    },
    async getMediaList(course) {
        //https://tuitworkspace/api/workshops/medialist/pocketcodei
        return ApiService.get(media_resource, course);
    }
};

export default WorkshopService;
