import router from "../router";
import store from "../store";
import auth from "./index";
import ApiService from "../api";
import JwtService from "../api/jwt.service";
const LOGIN_URL = "login";
const LOGIN_URL_ADMIN = "admin";
const REGISTER_URL = "account/register";
const RESET_URL = "account/reset";
const ACTIVATION_URL = "account/activate";

export default {
    loginAdmin(creds, callback) {
        ApiService.post(LOGIN_URL_ADMIN, creds)
            .then(res => {
                if (res.data) {
                    auth.storeToken(res.data);
                    if (callback) callback(true);
                }
            })
            .catch(e => {
                if (e.response.data) {
                    if (callback) callback(e.response.data);
                }
            });
    },
    login(creds, redirect, callback) {
        ApiService.post(LOGIN_URL, creds)
            .then(res => {
                if (res.data) {
                    auth.storeToken(res.data);
                    if (redirect) router.push({ name: redirect });
                    if (callback) callback(true);
                }
            })
            .catch(e => {
                if (e.response.data) {
                    if (callback) callback(e.response.data);
                }
            });
    },

    logout() {
        store.dispatch("auth/clear");
        JwtService.destroyToken();
        ApiService.cleanUp();
    },

    register(creds, callback) {
        ApiService.post(REGISTER_URL, creds)
            .then(res => {
                if (callback)
                    callback({ activationkey: res.headers.activationkey });
            })
            .catch(e => {
                if (e.response.data) {
                    if (callback) callback(e.response.data);
                }
            });
    },

    resetPassword(creds, callback) {
        ApiService.post(RESET_URL, creds)
            .then(res => {
                if (callback) callback(res);
            })
            .catch(e => {
                if (e.response.data) {
                    if (callback) callback(e.response.data);
                }
            });
    },

    activate(creds, redirect, callback) {
        ApiService.post(ACTIVATION_URL, creds)
            .then(() => {
                if (redirect) router.push({ name: redirect });
                if (callback) callback(true);
            })
            .catch(e => {
                if (e.response.data) {
                    if (callback) callback(e.response.data);
                }
            });
    }
};
