<template>
    <div>
        <b-navbar variant="dark" fixed="top" class="navbar">
            <b-navbar-nav class="ml-auto">
                <b-navbar-nav class="ml-auto">
                    <b-nav-item
                        class="navbar-nav"
                        style="position: absolute;top: 50%;right: 320px;transform: translate(-50%,-50%);"
                    >
                        <router-link
                            style="padding-top: 20%;"
                            v-if="isAuthenticated && hasRoleAdmin"
                            to="/password-reset"
                            ><i class="fa fa-user fa-lg"></i>
                        </router-link>
                    </b-nav-item>

                    <b-nav-item
                        class="navbar-nav"
                        style="position: absolute;top: 50%;right: 280px;transform: translate(-50%,-50%);"
                    >
                        <router-link
                            style="padding-top: 20%;"
                            v-if="isAuthenticated && hasRoleAdmin"
                            to="/"
                            v-on:click.native="clearCache()"
                            ><i class="fa fa-sign-out-alt fa-lg"></i>
                        </router-link>
                    </b-nav-item>
                </b-navbar-nav>
                <b-nav-item class="navbar-nav">
                    <div id="int-header-logo" @click="openURL()">
                        <a
                            href="https://www.tugraz.at"
                            title="TU Graz Home"
                            target="_blank"
                            ><div id="int-header-logo-claim">
                                <div class="int-header-logo-claim-single">
                                    WISSEN
                                </div>
                                <div class="int-header-logo-claim-single">
                                    TECHNIK
                                </div>
                                <div class="int-header-logo-claim-single">
                                    LEIDENSCHAFT
                                </div>
                            </div>
                            <svg
                                id="int-header-logo-img"
                                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                                xmlns="http://www.w3.org/2000/svg"
                                xml:space="preserve"
                                height="51.862"
                                width="141.1"
                                version="1.1"
                                xmlns:cc="http://creativecommons.org/ns#"
                                xmlns:dc="http://purl.org/dc/elements/1.1/"
                                viewBox="0 0 141.10001 51.862499"
                            >
                                <g transform="matrix(1.25 0 0 -1.25 0 51.862)">
                                    <g transform="scale(.1)">
                                        <path
                                            style="fill:#e4154b"
                                            d="m0 103.73h207.45v207.46l-207.45 0.01v-207.47z"
                                        ></path>
                                        <path
                                            style="fill:#e4154b"
                                            d="m228.19 103.73h207.46v207.46h-207.46v-207.46z"
                                        ></path>
                                        <path
                                            style="fill:#e4154b"
                                            d="m456.41 103.73h207.44v207.46h-207.44v-207.46z"
                                        ></path>
                                        <path
                                            style="fill:#e4154b"
                                            d="m103.72 0h207.47v207.46h-207.47v-207.46z"
                                        ></path>
                                        <path
                                            style="fill:#e4154b"
                                            d="m352.68 207.46h207.44v207.46h-207.44v-207.46z"
                                        ></path>
                                        <path
                                            style="fill:#231f20"
                                            d="m751.04 277.91h-66.426v33.195h171.19v-33.195h-66.407v-173.73h-38.359v173.73"
                                        ></path>
                                        <path
                                            style="fill:#231f20"
                                            d="m1048.3 180.22c0-12.461-2.25-23.711-6.72-33.75-4.5-10.039-10.61-18.555-18.36-25.567-7.76-7.031-16.9-12.421-27.503-16.21-10.605-3.809-22.109-5.7036-34.551-5.7036-12.422 0-23.945 1.8946-34.551 5.7036-10.605 3.789-19.824 9.179-27.656 16.21-7.851 7.012-13.984 15.528-18.34 25.567-4.394 10.039-6.582 21.289-6.582 33.75v130.89h38.379v-129.59c0-5.039 0.801-10.351 2.442-15.898 1.64-5.547 4.336-10.664 8.125-15.332s8.789-8.516 15.039-11.523c6.211-3.008 13.926-4.512 23.144-4.512 9.199 0 16.914 1.504 23.145 4.512 6.23 3.007 11.25 6.855 15.039 11.523 3.77 4.668 6.48 9.785 8.12 15.332 1.63 5.547 2.45 10.859 2.45 15.898v129.59h38.38v-130.89"
                                        ></path>
                                        <path
                                            style="fill:#231f20"
                                            d="m832.56 75.664c-7.597 3.2812-17.46 4.8632-25.332 4.8632-22.929 0-35.605-14.434-35.605-33.184 0-18.613 12.383-32.637 33.34-32.637 5.351 0 9.59 0.5274 12.969 1.3086v23.867h-20.84v14.414h39.687v-49.297c-10.41-2.6172-21.25-4.707-31.816-4.707-31.797 0-53.906 14.805-53.906 45.742 0 31.348 20.566 48.906 53.906 48.906 11.406 0 20.41-1.4453 28.867-3.8086l-1.27-15.469"
                                        ></path>
                                        <path
                                            style="fill:#231f20"
                                            d="m856.2 69.375h16.758v-15.332h0.293c0.84 6.289 8.574 16.914 19.824 16.914 1.836 0 3.828 0 5.782-0.5273v-17.715c-1.68 0.918-5.059 1.4454-8.457 1.4454-15.333 0-15.333-17.832-15.333-27.52v-24.785h-18.867v67.52"
                                        ></path>
                                        <path
                                            style="fill:#231f20"
                                            d="m913.75 65.84c7.324 3.1446 17.187 5.1172 25.215 5.1172 22.09 0 31.23-8.5351 31.23-28.457v-8.6523c0-6.8165 0.156-11.934 0.293-16.914 0.137-5.1172 0.41-9.8242 0.84-15.078h-16.602c-0.703 3.5352-0.703 8.0078-0.839 10.098h-0.293c-4.36-7.4618-13.81-11.661-22.38-11.661-12.793 0-25.332 7.207-25.332 20.059 0 10.078 5.195 15.976 12.383 19.258 7.187 3.2812 16.464 3.9453 24.355 3.9453h10.41c0 10.879-5.195 14.551-16.328 14.551-8.008 0-16.035-2.8907-22.363-7.3438l-0.586 15.078zm22.11-52.715c5.782 0 10.274 2.3633 13.223 6.0352 3.105 3.8086 3.945 8.6523 3.945 13.906h-8.164c-8.437 0-20.957-1.3086-20.957-11.68 0-5.7617 5.195-8.2617 11.953-8.2617"
                                        ></path>
                                        <path
                                            style="fill:#231f20"
                                            d="m985.69 69.375h57.422v-14.414l-36.04-39.473h37.31v-13.633h-60.235v14.297l36.715 39.59h-35.172v13.633"
                                        ></path>
                                        <path
                                            style="fill:#e4154b"
                                            d="m1059.6 0h69.102v69.121h-69.102v-69.121z"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Navbar",
    computed: {
        ...mapGetters({
            hasRoleAdmin: "auth/hasRoleAdmin"
        }),
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        }
    },
    methods: {
        clearCache() {
            this.$store.dispatch("auth/clear");
            localStorage.clear();
        },
        openURL() {
            window.open("https://www.tugraz.at", "_blank");
        }
    }
};
</script>

<style scoped>
.navbar {
    margin-bottom: 2.5em;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.55);
    z-index: 9 !important;
}
.navbar-brand {
    color: #ffffff !important;
}
a:hover {
    text-decoration: none;
    color: #007bff !important;
}
.navbar-nav {
    color: #007bff !important;
}
a:not([href]):not([class]) {
    color: #ffffff;
    text-decoration: none;
}
a {
    color: #ffffff;
    text-decoration: none;
}
.nav-link a:hover {
    color: #dc3545 !important;
}
</style>
