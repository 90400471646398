<template>
    <SidebarMenu
        :menu="menu"
        :collapsed="collapsed"
        style="z-index: 1050"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick"
    />
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import { mapGetters } from "vuex";

export default {
    name: "Sidebar",
    computed: {
        ...mapGetters({
            hasRoleAdmin: "auth/hasRoleAdmin"
        }),
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        }
    },
    components: {
        SidebarMenu
    },
    mounted() {
        this.buildMenu();
    },
    data() {
        return {
            collapsed: true,
            menu: []
        };
    },
    methods: {
        buildMenu() {
            const menu = [];
            const dashboard = {
                href: "/",
                icon: "fa fa-home"
            };

            const config = {
                href: "/configuration",
                icon: "fa fa-cogs"
            };

            menu.push(dashboard);
            menu.push(config);
            this.menu = menu;
        },
        // eslint-disable-next-line no-unused-vars
        onToggleCollapse(collapsed) {
            this.collapsed = !this.collapsed;
        },
        // eslint-disable-next-line no-unused-vars
        onItemClick(event, item, node) {
            this.collapsed = true;
        }
    }
};
</script>

<style scoped></style>
