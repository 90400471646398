import Vue from "vue";
import Vuex from "vuex";
import auth from "../auth/auth.store";
import level from "../levels/levels.store";
import workshop from "../workshops/workshops.store";
import { localStoragePlugin } from "./plugins";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        level,
        workshop
    },
    plugins: [localStoragePlugin]
});
