import Vue from "vue";
import LevelService from "./levels.service";

const getDefaultItem = () => ({
    id: null,
    bezeichnung: null,
    produkttyp: null
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: []
});

const level = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, "item", data);
        },
        updateList(state, data) {
            Vue.set(state, "list", data);
        },
        clear(state) {
            Vue.set(state, "item", getDefaultState().item);
            Vue.set(state, "list", getDefaultState().list);
        }
    },

    actions: {
        clear({ commit }) {
            commit("clear");
        },
        async create(context, client_data) {
            const { data } = await LevelService.create(client_data);
            context.commit("update", data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await LevelService.update(
                client_data.id,
                client_data
            );
            context.commit("update", data);
            return data;
        },
        async get(context, id) {
            const { data } = await LevelService.get(id);
            context.commit("update", data);
            return data;
        },
        async query(context, params) {
            const { data } = await LevelService.query(params);
            context.commit("updateList", data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await LevelService.delete(slug);
            context.commit("updateList", data);
            return data;
        }
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        }
    }
};

export default level;
