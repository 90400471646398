import JwtService from "../api/jwt.service";
import store from "../store/index";

export default {
    isInvalidToken(response) {
        const status = response.status;
        const error = response.data.error;

        return (
            status === 401 ||
            (status === 403 &&
                (error === "invalid_token" || error === "expired_token"))
        );
    },
    storeToken(response) {
        const auth = store.state.auth;
        JwtService.saveToken(response.access_token);

        auth.isLoggedIn = true;
        auth.accessToken = response.access_token;
        auth.friendlyUsername = response.friendlyUsername;
        auth.authorities = response.authorities;
        auth.userId = response.userId;
        store.dispatch("auth/update", auth);
    }
};
