import Vue from "vue";
import WorkshopService from "./workshops.service";

const getDefaultItem = () => ({
    id: null,
    levels: [],
    metainfo: null,
    workshopkey: null
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    workshopkey: null
});

const workshop = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, "item", data);
        },
        updateList(state, data) {
            Vue.set(state, "list", data);
        },
        clear(state) {
            Vue.set(state, "item", getDefaultState().item);
            Vue.set(state, "list", getDefaultState().list);
        },
        updateWorkshopKey(state, data) {
            Vue.set(state, "workshopkey", data);
        }
    },

    actions: {
        clear({ commit }) {
            commit("clear");
        },
        async setWorkshopKey(context, data) {
            context.commit("updateWorkshopKey", data);
            return data;
        },
        async create(context, client_data) {
            const { data } = await WorkshopService.create(client_data);
            context.commit("update", data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await WorkshopService.update(
                client_data.id,
                client_data
            );
            context.commit("update", data);
            return data;
        },
        async get(context, id) {
            const { data } = await WorkshopService.get(id);
            context.commit("update", data[0]);
            return data;
        },
        async query(context, params) {
            const { data } = await WorkshopService.query(params);
            context.commit("updateList", data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await WorkshopService.delete(slug);
            context.commit("updateList", data);
            return data;
        },
        async deleteKey(context, slug) {
            const { data } = await WorkshopService.delete(slug);
            context.commit("updateList", data);
            return data;
        },
        async deleteLevel(context, slug) {
            const { data } = await WorkshopService.delete(slug);
            context.commit("updateList", data);
            return data;
        }
    },

    getters: {
        data(state) {
            return state.item;
        },
        workshopkey(state) {
            return state.workshopkey;
        },
        list(state) {
            return state.list;
        }
    }
};

export default workshop;
