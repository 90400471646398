import { getToken } from "./jwt.service";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import auth from "../auth/auth.helpers";

const ApiService = {
    _401interceptor: null,

    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL =
            window.location.hostname.indexOf("localhost") > -1
                ? "http://tuitworkspace/api/"
                : window.location.protocol +
                  "//" +
                  window.location.hostname +
                  (window.location.hostname.indexOf("lampz.tugraz") > -1
                      ? "/~tuit-test/public/api/"
                      : "/public/api/");

        Vue.axios.defaults.headers.common["Cache-Control"] = "no-cache";
        Vue.axios.defaults.headers.common["Pragma"] = "no-cache";
        Vue.axios.defaults.headers.common["Expires"] =
            "Sat, 01 Jan 2000 00:00:00 GMT";
        this.useInterceptor();
        this.mount401Interceptor();
    },

    useInterceptor() {
        axios.interceptors.request.use(
            function(config) {
                const token = getToken();
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            function(err) {
                return Promise.reject(err);
            }
        );
    },

    mount401Interceptor() {
        this._401interceptor = Vue.axios.interceptors.response.use(
            response => response,
            async error => {
                if (
                    error.request.status == 401 ||
                    error.request.status == 403
                ) {
                    auth.logout();
                }
                throw error;
            }
        );
    },

    getLocation() {
        return Vue.axios.defaults.baseURL;
    },

    removeJwtHeader() {
        delete Vue.axios.defaults.headers.common["Authorization"];
    },

    unmount401Interceptor() {
        Vue.axios.interceptors.response.eject(this._401interceptor);
    },

    cleanUp() {
        this.removeJwtHeader();
        //this.unmount401Interceptor();
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`[VPI] ApiService ${error}`);
        });
    },

    get(resource, slug = "") {
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
            throw new Error(`[VPI] ApiService ${error}`);
        });
    },
    getExcel(resource, params) {
        return Vue.axios
            .post(`${resource}`, params, {
                responseType: "blob"
            })
            .catch(error => {
                throw new Error(`[VPI] ApiService ${error}`);
            });
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    delete(resource, slug) {
        return Vue.axios.delete(`${resource}/${slug}`).catch(error => {
            throw new Error(`[VPI] ApiService ${error}`);
        });
    }
};

export default ApiService;
