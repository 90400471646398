import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Toast from "./common/toast";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import ApiService from "./api";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import i18n from "@/i18n";
import { Vuelidate } from "vuelidate";
import numkeyboard from "vue-numkeyboard";
import "vue-numkeyboard/style.css";
import VueBrowserUpdate from "vue-browserupdate";

Vue.use(VueBrowserUpdate, {
    options: {
        required: { e: 0, f: -3, o: -2, s: -1, c: -3 },
        insecure: true,
        unsupported: true,
        mobile: false,
        style: "bottom",
        reminder: 0,
        reminderClosed: 2,
        no_permanent_hide: true
    }
});

Vue.browserUpdate.onshow(() => {});
Vue.use(numkeyboard);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;

Vue.use(VueSidebarMenu);
Vue.use(Vuelidate);
Toast.init();
ApiService.init();

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
